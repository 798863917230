import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/orientation/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/orientation/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/orientation/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/orientation/4.jpg';
// import p5 from 'assests/Photos/SchoolProgram/2024/orientation/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2024/orientation/6.jpg';


// import p5 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2023/RepublicDay/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/2023/RepublicDay/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/2023/RepublicDay/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/2023/RepublicDay/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/2023/RepublicDay/10.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Orientation2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/school-programs/orientation/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/orientation/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/orientation/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/orientation/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/orientation/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/orientation/6.webp`;
   
    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        }, 
       
       
//       {
//           src: p6,
//           source: p6,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p7,
//           source: p7,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p8,
//           source: p8,
//           rows: 1,
//           cols: 1,
//       }, 
//       {
//           src: p9,
//           source: p9,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p10,
//           source: p10,
//           rows: 1,
//           cols: 1,
//       }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                ORIENTATION PROGRAM	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Class: MONTESSORI- CLASS 12    Date: 31 MAY 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur organised a series of orientation programme for the parents for classes Montessori to Class 12 at the School Auditorium. The Orientation commenced with a warm welcome by the Principal Ms Sheeja Manoj, who highlighted the school’s mission, values and commitment to providing a holistic education and laid down the road map for the Academic year 2024-25 attuned with 21st century skills.  
                                    <br></br>
                                    The major objective of the programme was to make the parents and students be acquainted with the Academic Curriculum, an insight into the various practices, Assessment Strategies, School Programmes, Co-curricular and Co scholastic opportunities provided, the expected behaviour from students and ensuring parental participation in monitoring the performance and progress of their wards. It was also a time to introspect and retrospect by all the stakeholders as they embark on their collective responsibility towards the future of their children.
                                   
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ Individual commitment to a collective responsibility ”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Orientation2024;